// src/components/Noticia/NoticiaSocial.js

import React from 'react';
import { FaFacebookF, FaWhatsapp, FaLinkedinIn } from 'react-icons/fa'; // Import WhatsApp icon
import './NoticiaSocial.css';

const NoticiaSocial = () => {
  const postUrl = encodeURIComponent(window.location.href); // Encoded URL of the current page
  const postTitle = encodeURIComponent(document.title || 'Check out this post!'); // Encoded page title

  return (
    <div className="noticia-social">
      <h3 className="noticia-social-title">
        Compartir en las siguientes plataformas sociales
      </h3>
      <div className="noticia-social-icons">
        {/* Facebook Share */}
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${postUrl}`}
          target="_blank"
          rel="noopener noreferrer"
          className="noticia-social-icon-wrapper"
        >
          <FaFacebookF className="noticia-social-icon" />
        </a>

        {/* WhatsApp Share */}
        <a
          href={`https://wa.me/?text=${postTitle}%20${postUrl}`}
          target="_blank"
          rel="noopener noreferrer"
          className="noticia-social-icon-wrapper"
        >
          <FaWhatsapp className="noticia-social-icon" />
        </a>

        {/* LinkedIn Share */}
        <a
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${postUrl}`}
          target="_blank"
          rel="noopener noreferrer"
          className="noticia-social-icon-wrapper"
        >
          <FaLinkedinIn className="noticia-social-icon" />
        </a>
      </div>
    </div>
  );
};

export default NoticiaSocial;
