// File: src/components/Productos/ProductListDesktop.js
import React, { useState } from 'react';
import './ProductListDesktop.css';

function ProductListDesktop({ lineas, selectedSerie, setSelectedSerie }) {
  const [expandedLineaId, setExpandedLineaId] = useState(null);

  // Validación para evitar errores con datos faltantes
  const sortedLineas = (lineas || []).sort((a, b) => (a.posicion || 0) - (b.posicion || 0));

  const handleSerieClick = (serie) => {
    console.log('Serie seleccionada:', serie);
    setSelectedSerie(serie);
  };

  const handleReset = () => {
    setSelectedSerie(null);
    setExpandedLineaId(null);
  };

  return (
    <div className="product-list-desktop-container">
      <ul className="product-list-desktop-list">
        <h2 className="product-list-desktop-title">BUSCAR</h2>
        {sortedLineas.map((linea) => (
          <li key={linea.documentId}>
            <div
              className={`product-list-desktop-linea-header ${
                expandedLineaId === linea.documentId ? 'selected-linea' : ''
              }`}
              onClick={() =>
                setExpandedLineaId(
                  expandedLineaId === linea.documentId ? null : linea.documentId
                )
              }
            >
              <p>{linea.nombre}</p>
              <span className="material-icons">expand_more</span>
            </div>
            {expandedLineaId === linea.documentId && (
              <ul className="product-list-desktop-series-list">
                {(linea.series || []) // Asegura que `series` sea un arreglo vacío si es undefined
                  .sort((a, b) => (a.posicion || 0) - (b.posicion || 0))
                  .map((serie) => (
                    <li
                      key={serie.documentId}
                      onClick={() => handleSerieClick(serie)}
                      className={
                        selectedSerie?.documentId === serie.documentId
                          ? 'selected-serie'
                          : ''
                      }
                    >
                      {serie.nombre}
                    </li>
                  ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
      <button className="product-list-desktop-reset-button" onClick={handleReset}>
        RESTABLECER
      </button>
    </div>
  );
}

export default ProductListDesktop;
