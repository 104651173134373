import React, { Suspense } from 'react';
import { Link } from 'react-router-dom';
import './ModeloHeaderMobile.css';

const LazyImage = React.lazy(() => import('../LazyImage'));

const ModeloHeaderMobile = ({ nombre, serie, linea, descripcion, imagenUrl }) => {
  const imagePath = '/images/ModeloBanner/model-background-mobile.webp';

  // Extraemos los nombres de serie y línea, manejando valores predeterminados
  const serieNombre = serie || "Sin serie";
  const lineaNombre = linea || "Sin línea";

  // Clase condicional basada en la descripción
  const bannerClass = descripcion ? 'modelo-header-full-height' : 'modelo-header-reduced-height';

  return (
    <div
      className={`modelo-header-mobile-banner ${bannerClass}`}
      style={{ '--background-image': `url(${imagePath})` }}
    >
      <div className="modelo-header-mobile-ghost">{nombre}</div>

      <div className="modelo-header-mobile-container">
        <div className="modelo-header-mobile-image-container">
          <Suspense fallback={<div>Loading image...</div>}>
            <LazyImage src={imagenUrl} alt={nombre} className="modelo-header-mobile-image" />
          </Suspense>
        </div>
        <div className="modelo-header-mobile-texto">
          <h3 className="modelo-header-mobile-serie">
            <Link to="/productos" state={{ linea, serie }}>
              {lineaNombre} / {serieNombre}
            </Link>
          </h3>
          <h2 className="modelo-header-mobile-title">{nombre}</h2>
          {descripcion && <p className="modelo-header-mobile-description">{descripcion}</p>}
        </div>
      </div>
    </div>
  );
};

export default ModeloHeaderMobile;
