import { useNavigate } from 'react-router-dom';
import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './HomeSerieSliderDesktop.css'; // Updated custom styles
import nextArrow from '../../../../images/serieslider/next.png';
import prevArrow from '../../../../images/serieslider/prev.png';
import { ReactComponent as ShowcaseBackground } from './background-showcase.svg';


function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} home-serie-slider-desktop-arrow home-serie-slider-desktop-next`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={nextArrow} alt="Next" className="home-serie-slider-desktop-arrow-image" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} home-serie-slider-desktop-arrow home-serie-slider-desktop-prev`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={prevArrow} alt="Previous" className="home-serie-slider-desktop-arrow-image" />
    </div>
  );
}

const HomeSerieSliderDesktop = ({ series = [], sliderRef, loading, linea }) => {
  const navigate = useNavigate();

  // Modify handleButtonClick to navigate to Productos with linea and serie
  const handleButtonClick = (serie) => {
    navigate('/productos', { state: { linea, serie } });
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  return (
    <div className="home-serie-slider-desktop-background">
      <div className="home-serie-slider-desktop-zoomlion">
        <ShowcaseBackground />
      </div>

      <div className="home-serie-slider-desktop-wrapper">
        <div className="home-serie-slider-desktop-container">
          {!loading && (
            <Slider {...sliderSettings} ref={sliderRef}>
              {series.map((serie) => (  // Now series is safely initialized as an empty array if undefined
                <div key={serie.id} className="home-serie-slider-desktop-slide">
                  <div className="home-serie-slider-desktop-item">
                    <h3 className="home-serie-slider-desktop-label">{serie.nombre}</h3>  {/* Label now above the image */}
                    <img src={serie.image} alt={serie.nombre} className="home-serie-slider-desktop-image" />
                    {!serie.hidden && (
  <button 
    className="home-serie-slider-desktop-button"
    onClick={() => handleButtonClick(serie)}
  >
    VER MÁS
  </button>
)}

                  </div>
                </div>
              ))}
            </Slider>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeSerieSliderDesktop;
