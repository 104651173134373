import { useNavigate } from 'react-router-dom';
import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './HomeSerieSliderMobile.css';  // Estilos personalizados para móvil
import nextArrow from '../../../../images/serieslider/next.png';
import prevArrow from '../../../../images/serieslider/prev.png';

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} home-serie-slider-mobile-arrow home-serie-slider-mobile-next`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={nextArrow} alt="Next" className="home-serie-slider-mobile-arrow-image" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} home-serie-slider-mobile-arrow home-serie-slider-mobile-prev`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={prevArrow} alt="Previous" className="home-serie-slider-mobile-arrow-image" />
    </div>
  );
}

const HomeSerieSliderMobile = ({ series = [], sliderRef, loading, linea }) => {
  const navigate = useNavigate();

  // Update handleButtonClick to pass selected linea and serie to Productos
  const handleButtonClick = (serie) => {
    navigate('/productos', { state: { linea, serie } });
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  return (
    <div className="home-serie-slider-mobile-background">

      <div className="home-serie-slider-mobile-wrapper">
        <div className="home-serie-slider-mobile-container">
          {!loading && (
            <Slider {...sliderSettings} ref={sliderRef}>
              {series.map((serie) => (
                <div key={serie.id} className="home-serie-slider-mobile-slide">
                  <div className="home-serie-slider-mobile-item">
                    <h3 className="home-serie-slider-mobile-label">{serie.nombre}</h3>
                    <img src={serie.image} alt={serie.nombre} className="home-serie-slider-mobile-image" />
                    {!serie.hidden && (
  <button 
    className="home-serie-slider-mobile-button"
    onClick={() => handleButtonClick(serie)}  // Pass selected serie here
  >
    VER MÁS
  </button>
)}

                  </div>
                </div>
              ))}
            </Slider>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeSerieSliderMobile;
