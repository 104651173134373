import React, { useState, useEffect, useCallback, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import './Contacto.css';
import PageFooter from '../components/PageFooter';

const LazyPageBanner = React.lazy(() => import('../components/PageBanner'));
const LazyContactForm = React.lazy(() => import('../components/Contacto/ContactForm'));
const LazyContactCard = React.lazy(() => import('../components/Contacto/ContactCard'));
const LazyContactMap = React.lazy(() => import('../components/Contacto/ContactMap'));

const Contacto = () => {
  const location = useLocation();
  const modelo = location.state?.modelo || null;

  const [selectedOficina, setSelectedOficina] = useState(null);

  useEffect(() => {
    document.title = "Zoomlion Latinoamérica | Contacto";

    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement('meta');
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.content = "Conoce las oficinas y centros de atención de Zoomlion en Latinoamérica y ponte en contacto con nosotros.";

    window.scrollTo(0, 0);
  }, []);

  // Memoizamos handleOfficeChange para evitar que cambie en cada render
  const handleOfficeChange = useCallback((oficina) => {
    console.log("Cambio de oficina:", oficina); // Log para depuración
    setSelectedOficina(oficina);
  }, []);

  return (
    <div className="contact-page">
      <div className="contact-page-banner">
        <Suspense fallback={<div>Cargando Banner...</div>}>
          <LazyPageBanner />
        </Suspense>
      </div>
      <div className="contact-page-content">
        <div className="contact-page-information">
          <div className="contact-page-card">
            <Suspense fallback={<div>Cargando Oficinas...</div>}>
              <LazyContactCard onOfficeChange={handleOfficeChange} />
            </Suspense>
          </div>
          <div className="contact-page-map">
            <Suspense fallback={<div>Cargando Mapa...</div>}>
              <LazyContactMap oficina={selectedOficina} />
            </Suspense>
          </div>
        </div>
      </div>
      <div className="contact-page-footer">
        <PageFooter />
      </div>
    </div>
  );
};

export default Contacto;
