import React, { useEffect, useState, useRef } from 'react';
import HomeShowcaseDesktop from './HomeShowcaseDesktop/HomeShowcaseDesktop';
import HomeShowcaseMobile from './HomeShowcaseMobile/HomeShowcaseMobile';
import './HomeShowcaseSection.css'; // Updated custom styles

function HomeShowcaseSection() {
  const [lineas, setLineas] = useState([]);
  const [currentLinea, setCurrentLinea] = useState("");
  const [loading, setLoading] = useState(true);
  const lineaSliderRef = useRef(null);
  const serieSliderRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    fetchLineas();
  }, []);

  useEffect(() => {
    if (lineas.length > 0) {
      setCurrentLinea(lineas[0].id); // Set the first linea as the current linea
      setLoading(false);
      console.log("Lineas after fetch:", lineas); // 3. Verifica el estado lineas
      console.log("Current Linea set to:", lineas[0].id); // 4. Verifica el ID de la línea actual
    }
  }, [lineas]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fetchLineas = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/lineas?populate[icono_landing]=true&populate[series][populate]=imagen_landing`);
      const data = await response.json();
      console.log("API Response:", data); // Log para verificar la estructura de los datos
  
      if (data && data.data) {
        const fetchedLineas = data.data.map(item => {
          const iconoLanding = item.icono_landing?.url || ""; // Acceso directo a icono_landing
          const series = (item.series || []).map(serie => {
            const imagenLanding = serie.imagen_landing?.[0]?.url || ""; // Acceso directo a imagen_landing
  
            return {
              id: serie.id,
              nombre: serie.nombre || "Sin nombre", // Acceso directo al nombre de la serie
              image: imagenLanding,
              hidden: serie.hidden || false,
              posicion: serie.posicion || 0
            };
          });
  
          return {
            id: item.id,
            nombre: item.nombre || "Sin nombre", // Acceso directo al nombre de la línea
            image: iconoLanding,
            series: series.sort((a, b) => a.posicion - b.posicion), // Ordenar series
          };
        });
  
        console.log("Processed Lineas:", fetchedLineas); // Log para verificar los datos procesados
        setLineas(fetchedLineas);
      } else {
        console.error("Lineas data is not structured as expected:", data);
      }
    } catch (error) {
      console.error("Error fetching lineas:", error);
    }
  };
  
  const handleLineaChange = (index) => {
    const selectedLinea = lineas[index];
    if (selectedLinea) {
      setCurrentLinea(selectedLinea.id);
      if (serieSliderRef.current) {
        serieSliderRef.current.slickGoTo(0);
      }
    }
  };

  const currentLineaSeries = lineas.find(linea => linea.id === currentLinea)?.series || [];
  console.log("Lineas state before render:", lineas); // 5. Verifica lineas antes del renderizado
  console.log("Current Linea:", currentLinea); // 6. Verifica el estado actual de currentLinea
  console.log("Current Linea Series:", currentLineaSeries); // 7. Verifica las series actuales


  return (
    <div className="home-showcase-section-container">
      {isMobile ? (
        <HomeShowcaseMobile
          lineas={lineas}
          currentLinea={currentLinea}
          currentLineaSeries={currentLineaSeries}
          handleLineaChange={handleLineaChange}
          lineaSliderRef={lineaSliderRef}
          serieSliderRef={serieSliderRef}
          loading={loading}
        />
      ) : (
        <HomeShowcaseDesktop
          lineas={lineas}
          currentLinea={currentLinea}
          currentLineaSeries={currentLineaSeries}
          handleLineaChange={handleLineaChange}
          lineaSliderRef={lineaSliderRef}
          serieSliderRef={serieSliderRef}
          loading={loading}
        />
      )}

      {loading && <div>Loading...</div>}
    </div>
  );
}

export default HomeShowcaseSection;
