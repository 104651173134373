// src/components/Modelo/ModeloDestacadoDesktop/ModeloDestacadoDesktop.js
import React, { useEffect, useRef, useState } from 'react';
import ModeloDestacadoDesktopCard from './ModeloDestacadoDesktopCard';
import './ModeloDestacadoDesktop.css';

const ModeloDestacadoDesktop = ({ linea }) => {
  const [destacados, setDestacados] = useState([]);
  const [error, setError] = useState(null);
  const cardRef = useRef([]); // Ref para las tarjetas

  useEffect(() => {
    const fetchDestacados = async () => {
      if (!linea || !linea.documentId) {
        setError('No se pudo encontrar la línea seleccionada.');
        return;
      }

      try {
        // Llamada a la API
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/lineas?filters[documentId]=${linea.documentId}&populate=destacados.serie,destacados.imagen_card`
        );
        const data = await response.json();

        if (!response.ok || !data.data || data.data.length === 0) {
          throw new Error('Error fetching destacados');
        }

        // Procesar destacados
        const destacadosData = data.data[0].destacados.map((destacado) => {
          const { nombre, slug, descripcion, serie, imagen_card } = destacado;
          return {
            nombre,
            slug,
            descripcion,
            serie: serie?.nombre || 'Sin serie', // Validamos que exista serie
            imagenCard:
              imagen_card?.formats?.medium?.url || imagen_card?.url || null, // Prioridad a formato 'medium'
          };
        });

        setDestacados(destacadosData.slice(0, 3)); // Limitamos a los 3 primeros
      } catch (error) {
        console.error('Error fetching destacados:', error);
        setError('Error cargando los destacados.');
      }
    };

    fetchDestacados();
  }, [linea]);

  useEffect(() => {
    // IntersectionObserver para animación al hacer scroll
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.2 }
    );

    // Observamos cada tarjeta
    cardRef.current.forEach((card) => {
      if (card) {
        observer.observe(card);
      }
    });

    // Cleanup al desmontar el componente
    return () => {
      cardRef.current.forEach((card) => {
        if (card) {
          observer.unobserve(card);
        }
      });
    };
  }, [destacados]);

  if (error) {
    return <div className="modelo-destacado-desktop-error">{error}</div>;
  }

  return (
    <div className="modelo-destacado-section">
      <div className="modelo-destacado-container">
        <h3 className="modelo-destacado-title">Productos Recomendados</h3>
        <div className="modelo-destacado-desktop">
          {destacados.length > 0 ? (
            destacados.map((modelo, index) => (
              <div
                key={index}
                className="modelo-destacado-card-wrapper"
                ref={(el) => (cardRef.current[index] = el)} // Asignamos ref para cada tarjeta
              >
                <ModeloDestacadoDesktopCard
                  modelo={modelo.nombre}
                  slug={modelo.slug}
                  descripcion={modelo.descripcion}
                  serie={modelo.serie} // Nombre de la serie
                  imagenCard={modelo.imagenCard} // URL de la imagen
                />
              </div>
            ))
          ) : (
            <div>No hay modelos destacados.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModeloDestacadoDesktop;
