// src/components/HomeNoticiasSectionDesktop.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import HomeNoticiasSectionDesktopCard from './HomeNoticiasSectionDesktopCard';
import './HomeNoticiasSectionDesktop.css';

const HomeNoticiasSectionDesktop = () => {
  const [noticias, setNoticias] = useState([]);

  useEffect(() => {
    const fetchNoticias = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/noticias?populate=previsualizacion`);
        const data = await response.json();
        console.log('API Response:', data); // Verifica la estructura de la respuesta

        // Procesar las noticias
        const noticiasArray = data.data.map((noticia) => ({
          id: noticia.id,
          titulo: noticia.titulo || 'Sin título',
          contenido: noticia.contenido || 'Sin contenido',
          previsualizacion: noticia.previsualizacion?.[0]?.url || '', // Acceso directo a previsualización
          fechaPublicacion: noticia.publishedAt || 'Sin fecha',
          slug: noticia.slug || '', // Acceso directo a slug
        }));

        // Ordenar por fecha de publicación en orden descendente y tomar las 3 más recientes
        const sortedNoticias = noticiasArray.sort(
          (a, b) => new Date(b.fechaPublicacion) - new Date(a.fechaPublicacion)
        );

        setNoticias(sortedNoticias.slice(0, 3));
      } catch (error) {
        console.error('Error fetching noticias:', error);
      }
    };

    fetchNoticias();
  }, []);

  return (
    <div className="home-noticias-section-desktop">
      <div className="home-noticias-section-desktop-container">
        <h2 className="home-noticias-section-desktop-title">Noticias</h2>
        <div className="home-noticias-section-desktop-grid">
          {noticias.map((noticia) => (
            <HomeNoticiasSectionDesktopCard key={noticia.id} noticia={noticia} />
          ))}
        </div>
        {/* Botón Ver Más */}
        <div className="home-noticias-section-desktop-ver-mas-wrapper">
          <Link to="/noticias" className="home-noticias-section-desktop-ver-mas">
            VER MÁS
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomeNoticiasSectionDesktop;
