import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import './HomeContactoCardMobile.css';

const HomeContactoCardMobile = ({ selectedCountry, onCountryChange, countries }) => {
  const handleCountryChange = (event) => {
    const countryName = event.target.value;
    const selected = countries.find(country => country.name === countryName);
    onCountryChange(selected || null); // Si no hay selección válida, pasa null
  };

  return (
    <>
      <div className="home-contacto-card-mobile-header">
        <h3 className="home-contacto-card-mobile-title">
          Creamos valor para nuestros clientes en 19 países de Latinoamérica
        </h3>
      </div>
      <div className="home-contacto-card-mobile">
        {/* Selector de países */}
        <select
          value={selectedCountry?.name || ''}
          onChange={handleCountryChange}
          className="home-contacto-card-mobile-selector"
        >
          {!selectedCountry && (
            <option value="" disabled>Selecciona un país</option>
          )}
{[...countries]
  .sort((a, b) => a.name.localeCompare(b.name)) // Ordena los países alfabéticamente
  .map((country) => (
    <option key={country.name} value={country.name}>
      {country.name}
    </option>
  ))}

        </select>

        {/* Renderizado condicional basado en selectedCountry */}
        {selectedCountry && (
          <>
            <div className="home-contacto-card-mobile-details">
              <ul>
                <li>Representante: {selectedCountry.representante}</li>
                <li>Teléfono: +{selectedCountry.telefono}</li>
                <li>Dirección: {selectedCountry.direccion}</li>
                <li>Correo: {selectedCountry.email}</li>
              </ul>
            </div>
            <div className="home-contacto-card-mobile-button-container">
              <div
                className="home-contacto-card-mobile-button"
                onClick={() => (window.location.href = '/contacto')}
              >
                <div className="home-contacto-card-mobile-button-content">
                  <span>CONTÁCTANOS</span>
                </div>
                <div className="home-contacto-card-mobile-button-arrow">
                  <FaMapMarkerAlt className="home-contacto-card-mobile-button-arrow-icon" />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default HomeContactoCardMobile;
