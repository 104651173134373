import React, { useState, useEffect, Suspense } from 'react';
import PageFooter from '../components/PageFooter';
import NoticiaFiltro from '../components/Noticias/NoticiaFiltro';
import NoticiaGrid from '../components/Noticias/NoticiaGrid';
import './Noticias.css';

const LazyPageBanner = React.lazy(() => import('../components/PageBanner'));

const Noticias = () => {
  const [noticias, setNoticias] = useState([]);
  const [filteredNoticias, setFilteredNoticias] = useState([]);
  const [visibleNoticias, setVisibleNoticias] = useState(6); // Muestra 6 noticias al inicio
  const [sortOrder, setSortOrder] = useState('reciente'); // Orden por defecto es 'Más recientes'

  useEffect(() => {
    const fetchNoticias = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/noticias?populate=previsualizacion`
        );
        const { data } = await response.json();

        // Procesar noticias según respuesta de la API
        const noticiasArray = data.map((noticia) => ({
          documentId: noticia.documentId,
          titulo: noticia.titulo,
          slug: noticia.slug,
          bajada: noticia.bajada,
          previsualizacion: noticia.previsualizacion?.[0]?.url || '',
          fechaPublicacion: noticia.publishedAt || noticia.createdAt, // Usar la fecha como string
        }));

        setNoticias(noticiasArray);
        setFilteredNoticias(noticiasArray);
      } catch (error) {
        console.error('Error fetching noticias:', error);
      }
    };

    fetchNoticias();
  }, []);

  const handleSearch = (searchTerm) => {
    const filtered = noticias.filter((noticia) =>
      noticia.titulo.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredNoticias(filtered);
  };

  const handleSort = (order) => {
    if (order === 'antigua') {
      setFilteredNoticias([...filteredNoticias].reverse());
    } else {
      setFilteredNoticias([...noticias]); // Resetear al orden inicial
    }
    setSortOrder(order);
  };

  const handleLoadMore = () => {
    setVisibleNoticias((prevVisible) => prevVisible + 3); // Cargar 3 noticias más
  };

  return (
    <>
      <div className="noticias-page">
        <div className="noticias-page-banner">
          <Suspense fallback={<div>Loading Banner...</div>}>
            <LazyPageBanner />
          </Suspense>
        </div>

        <h2 className="noticias-page-h2">Buscar noticias</h2>
        <div className="noticias-page-filtro">
          <NoticiaFiltro onSearch={handleSearch} onSort={handleSort} />
        </div>

        <h2 className="noticias-page-h2-resultados">Resultados</h2>
        <div className="noticias-page-container">
          <NoticiaGrid noticias={filteredNoticias.slice(0, visibleNoticias)} />
        </div>

        {filteredNoticias.length > visibleNoticias && (
          <div className="noticias-page-ver-mas-container">
            <button className="noticias-page-ver-mas" onClick={handleLoadMore}>
              Ver más
            </button>
          </div>
        )}

        <div className="noticias-page-footer">
          <PageFooter />
        </div>
      </div>
    </>
  );
};

export default Noticias;
