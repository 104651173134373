import React, { useState } from 'react';
import './ProductListMobile.css';

function ProductListMobile({ lineas, selectedSerie, setSelectedSerie }) {
  const [selectedLineaDocumentId, setSelectedLineaDocumentId] = useState(null);

  const handleLineaChange = (event) => {
    const lineaDocumentId = event.target.value;
    console.log('Línea seleccionada con documentId:', lineaDocumentId);
    setSelectedLineaDocumentId(lineaDocumentId === selectedLineaDocumentId ? null : lineaDocumentId);
    setSelectedSerie(null); // Resetea la selección de la serie al cambiar de línea
  };

  const handleSerieChange = (event) => {
    const serieDocumentId = event.target.value;
    const selectedLinea = lineas.find((linea) => linea.documentId === selectedLineaDocumentId);
    const serie = selectedLinea?.series.find((serie) => serie.documentId === serieDocumentId);
    console.log('Serie seleccionada:', serie);
    setSelectedSerie(serie);
  };

  const handleReset = () => {
    console.log('Restableciendo selección...');
    setSelectedLineaDocumentId(null);
    setSelectedSerie(null);
  };

  const sortedLineas = (lineas || []).sort((a, b) => (a.posicion || 0) - (b.posicion || 0));

  return (
    <div className="product-list-mobile-container">
      <h2 className="product-list-title">Buscar</h2>

      {/* Selector para Líneas */}
      <div className="product-list-mobile-select-container">
        <label htmlFor="linea-select">Línea:</label>
        <select
          id="linea-select"
          value={selectedLineaDocumentId || ''}
          onChange={handleLineaChange}
          className="product-list-mobile-select"
        >
          <option value="" disabled>
            Selecciona una línea
          </option>
          {sortedLineas.map((linea) => (
            <option key={linea.documentId} value={linea.documentId}>
              {linea.nombre}
            </option>
          ))}
        </select>
      </div>

      {/* Selector para Series */}
      {selectedLineaDocumentId && (
        <div className="product-list-mobile-select-container">
          <label htmlFor="serie-select">Serie:</label>
          <select
            id="serie-select"
            value={selectedSerie?.documentId || ''}
            onChange={handleSerieChange}
            className="product-list-mobile-select"
          >
            <option value="" disabled>
              Selecciona una serie
            </option>
            {lineas
              .find((linea) => linea.documentId === selectedLineaDocumentId)
              ?.series.map((serie) => (
                <option key={serie.documentId} value={serie.documentId}>
                  {serie.nombre}
                </option>
              ))}
          </select>
        </div>
      )}

      <button className="product-list-mobile-reset-button" onClick={handleReset}>
        RESTABLECER
      </button>
    </div>
  );
}

export default ProductListMobile;
