// File: src/pages/Productos.js
import React, { useEffect, useState } from 'react';
import ProductDataProvider from '../components/Productos/ProductDataProvider';
import ProductListWrapper from '../components/Productos/ProductListWrapper';
import ProductGrid from '../components/Productos/ProductGrid';
import PageBanner from '../components/PageBanner';
import PageFooter from '../components/PageFooter';
import './Productos.css';

function Productos() {
  useEffect(() => {
    document.title = 'Zoomlion Latinoamérica | Productos';
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement('meta');
      metaDescription.name = 'description';
      document.head.appendChild(metaDescription);
    }
    metaDescription.content =
      'Consulta el catálogo completo de maquinaria Zoomlion disponible para Latinoamérica.';
  }, []);

  return (
    <div className="products-page-container">
      <div className="products-page-banner">
        <PageBanner />
      </div>
      <ProductDataProvider>
        {({ lineas, selectedSerie, setSelectedSerie, modelos, loadingModelos, error }) => (
          <div className="products-page-content">
            <ProductListWrapper
              lineasProp={lineas}
              selectedSerie={selectedSerie}
              setSelectedSerie={setSelectedSerie}
            />
            <ProductGrid
              modelos={modelos}
              selectedSerieName={selectedSerie?.nombre} // Pasamos el nombre de la serie seleccionada
              loading={loadingModelos}
              error={error}
            />
          </div>
        )}
      </ProductDataProvider>
      <div className="products-page-footer">
        <PageFooter />
      </div>
    </div>
  );
}

export default Productos;
