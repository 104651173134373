import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LazyImage from '../LazyImage'; // Import LazyImage
import './ModelCard.css';
import ModelModal from './ModelModal'; // Import the new ModelModal

function ModelCard({ slug, nombre, imagen, especificaciones }) {
  const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    // GA4 event for clicking on "Detalles"
    window.gtag('event', 'view_details', {
      event_category: 'engagement',
      model_name: nombre, // Use model_name as a consistent parameter
      value: 1
    });

    setModalOpen(!isModalOpen);
  };

  const handleVerMasClick = () => {
    // GA4 event for clicking on "Ver Más"
    window.gtag('event', 'view_more', {
      event_category: 'navigation',
      model_name: nombre, // Use model_name as a consistent parameter
      value: 1
    });
  };

  return (
    <div className="model-card">
      <LazyImage src={imagen} alt={nombre} className="model-card-image" />
      <h3 className="model-card-name">{nombre}</h3>
      <ul className="model-card-specs">
        {especificaciones.slice(0, 3).length > 0 ? ( // Limit to first 3 specifications
          especificaciones.slice(0, 3).map((especificacion, index) => ( // Use slice method
            <li key={index}>
              <span className="model-card-spec-name">{especificacion.name}:</span>{especificacion.value}
            </li>
          ))
        ) : (
          <li className="no-specs-message">No hay especificaciones disponibles</li>
        )}
      </ul>
      <div className="model-card-footer">
        <Link
          to={`/modelo/${slug}`}
          className="model-card-button vermas-button"
          onClick={handleVerMasClick} // GA4 event for "Ver Más"
        >
          VER MÁS
        </Link>
        <button className="model-card-button modal-button" onClick={toggleModal}>
          DETALLES
        </button>
      </div>

      {isModalOpen && (
        <ModelModal
          especificaciones={especificaciones}
          onClose={toggleModal}
          nombre={nombre}
        />
      )}
    </div>
  );
}

export default ModelCard;
