import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './Modelo.css';
import ModeloSpecs from '../components/Modelo/ModeloSpecs';
import ModeloTable from '../components/Modelo/ModeloTable';
import PageFooter from '../components/PageFooter';
import ModeloCotizacion from '../components/Modelo/ModeloCotizacion';
import ModeloFicha from '../components/Modelo/ModeloFicha';
import ModeloHeaderWrapper from '../components/Modelo/ModeloHeaderWrapper';
import ModeloDestacadoWrapper from '../components/Modelo/ModeloDestacado/ModeloDestacadoWrapper';
import PageAlerta from '../components/PageAlerta';

const Modelo = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [modelo, setModelo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [slug]);

  useEffect(() => {
    const fetchModelData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/modelos?filters[slug][$eq]=${slug}&populate=*`
        );
        const data = await response.json();
    
        if (!response.ok || !data.data || data.data.length === 0) {
          throw new Error(`Error fetching model details for slug: ${slug}`);
        }
    
        const modeloData = data.data[0];
    
        const modelo = {
          documentId: modeloData.documentId || null,
          nombre: modeloData.nombre || "Sin nombre",
          descripcion: modeloData.descripcion || "Descripción no disponible.",
          slug: modeloData.slug || null,
          serie: modeloData.serie
            ? {
                documentId: modeloData.serie.documentId || null,
                nombre: modeloData.serie.nombre || "Sin serie",
              }
            : { documentId: null, nombre: "Sin serie" },
          linea: modeloData.linea
            ? {
                documentId: modeloData.linea.documentId || null,
                nombre: modeloData.linea.nombre || "Sin línea",
              }
            : { documentId: null, nombre: "Sin línea" },
          imagenCardUrl:
            modeloData.imagen_card?.url ||
            modeloData.imagen_card?.formats?.medium?.url ||
            modeloData.imagen_card?.formats?.small?.url ||
            "/images/default-banner.webp",
          especificaciones: (modeloData.especificaciones || []).map((especificacion) => ({
            name: especificacion.name || "Sin nombre",
            value: especificacion.value || "Sin valor",
          })),
          createdAt: modeloData.createdAt || null,
          updatedAt: modeloData.updatedAt || null,
          publishedAt: modeloData.publishedAt || null,
        };
    
        setModelo(modelo);
      } catch (error) {
        console.error("Error fetching model data:", error);
        setError("Error loading model data.");
      } finally {
        setLoading(false);
      }
    };
    
    fetchModelData();
  }, [slug]);

  // Nuevo useEffect para GA4
  useEffect(() => {
    if (modelo) {
      // Enviar evento a GA4
      window.gtag('event', 'page_view_model', {
        event_category: 'page_view',
        model_name: modelo.nombre, // Enviar el nombre del modelo
        model_slug: modelo.slug,  // Enviar el slug del modelo
      });
    }
  }, [modelo]);

  useEffect(() => {
    if (modelo) {
      document.title = `Zoomlion Latinoamérica | ${modelo.nombre}`;

      let metaDescription = document.querySelector('meta[name="description"]');
      if (!metaDescription) {
        metaDescription = document.createElement('meta');
        metaDescription.name = "description";
        document.head.appendChild(metaDescription);
      }
      metaDescription.content = modelo.descripcion || "Descripción no disponible.";
    }
  }, [modelo]);

  if (loading) {
    return (
      <div className="modelo-page-loading">
        <div className="spinner">Cargando...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="modelo-page-error">
        <h2>Ocurrió un error</h2>
        <p>{error}</p>
        <button onClick={() => navigate(-1)} className="modelo-page-button">
          Volver
        </button>
      </div>
    );
  }

  if (!modelo) {
    return <div>Error al cargar los detalles del modelo.</div>;
  }

  const hasEspecificaciones = modelo.especificaciones && modelo.especificaciones.length > 0;
  const headerClass = modelo.descripcion ? "modelo-page-header" : "modelo-page-header reduced-header-mobile";

  return (
    <>
      <div className={headerClass}>
        <ModeloHeaderWrapper
          nombre={modelo.nombre}
          serie={modelo.serie}
          linea={modelo.linea}
          descripcion={modelo.descripcion}
          imagenUrl={modelo.imagenCardUrl}
        />
      </div>

      <div className="modelo-page-container">
        {hasEspecificaciones && (
          <div className="modelo-page-detail">
            {modelo.especificaciones.length === 3 && (
              <ModeloSpecs specs={modelo.especificaciones} />
            )}

            {modelo.especificaciones.length > 3 && (
              <>
                <ModeloSpecs specs={modelo.especificaciones} />
                <ModeloTable specs={modelo.especificaciones} />
              </>
            )}
          </div>
        )}
      </div>

      <div className="modelo-page-buttons-container">
        <ModeloCotizacion modelo={modelo} />
        <ModeloFicha modelo={modelo} />
      </div>

      <div className="modelo-page-destacado">
        <ModeloDestacadoWrapper linea={modelo.linea} />
      </div>

      <div className="modelo-page-footer">
        <PageFooter />
      </div>
    </>
  );
};

export default Modelo;