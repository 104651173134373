// File: src/components/Productos/ProductListWrapper.js
import React, { useState, useEffect } from 'react';
import ProductListDesktop from './ProductListDesktop';
import ProductListMobile from './ProductListMobile';

function ProductListWrapper({ lineasProp, selectedSerie, setSelectedSerie }) {
  const [isMobile, setIsMobile] = useState(false);

  // Detectar el tamaño de la ventana para renderizar el componente adecuado
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Establece la vista móvil si el ancho es menor a 768px
    };

    handleResize(); // Inicializa el estado con el tamaño actual de la ventana
    window.addEventListener('resize', handleResize); // Escucha los cambios de tamaño de la ventana

    return () => {
      window.removeEventListener('resize', handleResize); // Limpia el evento al desmontar el componente
    };
  }, []);

  if (!lineasProp.length) return <div>Loading líneas y series...</div>;

  return isMobile ? (
    <ProductListMobile
      lineas={lineasProp}
      selectedSerie={selectedSerie}
      setSelectedSerie={setSelectedSerie}
    />
  ) : (
    <ProductListDesktop
      lineas={lineasProp}
      selectedSerie={selectedSerie}
      setSelectedSerie={setSelectedSerie}
    />
  );
}

export default ProductListWrapper;
